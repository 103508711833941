var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.profilePage && _vm.data.properties.filed_content != 'Hide')?_c('div',{style:(_vm.getElementStyle)},[_c('el-row',{attrs:{"align":"middle"}},[(!_vm.data.properties.hideLabel)?_c('el-col',{attrs:{"span":_vm.showLabel && !_vm.isDefalutPos ? 8 : _vm.data.description ? 20 : 24}},[(_vm.hasLabel)?_c('label',{class:{ 'highlighted-label': _vm.highlight },style:(_vm.getLabelStyles),attrs:{"for":"text"}},[_vm._v(_vm._s(_vm.label||_vm.data.label))]):_vm._e(),(_vm.data.validations.required && _vm.hasLabel && !_vm.isView)?_c('span',{staticStyle:{"color":"red","font-weight":"bold"}},[_vm._v(" * ")]):_vm._e(),_c('span',[(_vm.data.description)?_c('el-popover',{attrs:{"placement":"top-start","trigger":"hover","content":_vm.data.description}},[(_vm.data.description)?_c('span',[_c('i',{staticClass:"el-icon-info",attrs:{"title":_vm.data.description}})]):_vm._e()]):_vm._e()],1)]):_c('el-col',{ref:"colRef",style:(_vm.computedStyles),attrs:{"span":_vm.showLabel ? 4 : _vm.data.description ? 20 : 24}},[(_vm.hasLabel)?_c('span',{staticClass:"transparentcol"}):_vm._e(),(_vm.data.validations.required && _vm.hasLabel && !_vm.isView)?_c('span',{staticStyle:{"color":"red","font-weight":"bold"}},[_vm._v(" * ")]):_vm._e()]),_c('el-col',{staticClass:"execute-view",attrs:{"span":_vm.showLabel
      &&
      !_vm.isDefalutPos
      ?
      12
      :
      24}},[(!_vm.isView && _vm.data.properties.filed_content != 'Hide')?_c('div',[_c('el-radio-group',{attrs:{"disabled":_vm.isFromDocument ? false : _vm.checkReadonly() || _vm.readonly(),"readonly":_vm.readonly()},on:{"change":_vm.applyFormRules},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}},[_c('el-radio',{attrs:{"label":"YES"}},[_vm._v("YES")]),_c('el-radio',{attrs:{"label":"NO"}},[_vm._v("NO")])],1)],1):_vm._e(),(_vm.isView && _vm.data.properties.filed_content != 'Hide')?_c('p',[(_vm.form[_vm.data.key])?[_vm._v(_vm._s(_vm.form[_vm.data.key]))]:[_vm._v("-")]],2):_vm._e()])],1)],1):_c('div',[(_vm.isView)?_c('div',[_vm._v(" "+_vm._s(_vm.form[_vm.data.key])+" ")]):_c('div',[(_vm.data.properties.filed_content != 'Hide')?_c('div',[_c('el-radio-group',{attrs:{"disabled":_vm.isFromDocument ? false : _vm.checkReadonly() ||_vm.readonly(),"readonly":_vm.readonly()},on:{"change":_vm.applyFormRules},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}},[_c('el-radio',{attrs:{"label":"YES"}},[_vm._v("YES")]),_c('el-radio',{attrs:{"label":"NO"}},[_vm._v("NO")])],1)],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }